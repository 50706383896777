/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var clickable_badge_init,
  create_button_handlers,
  document_ready,
  filter_vfnn_by_agency_id,
  init_datatable,
  loader_init,
  pjaxAlert,
  pjax_alert_cookie,
  pjax_alert_showtimes,
  refresh,
  refresh_sw_download,
  start_refresh_sw_download,
  ultralink_delete_init,
  ultralink_edit_init,
  ultralink_new_init,
  remote_console_link_init;

create_button_handlers = function (model) {
  $(".btn-send-alarm")
    .off("click")
    .on("click", function () {
      var path, url;
      path = model.replace(/-/g, "_");
      if (is_number($(this).attr("id"))) {
        url = "/" + path + "s/" + $(this).attr("id") + "/new";
      } else {
        url = "/" + path + "s/new";
      }
      $("#modal-body-new-" + model).empty();
      return loadPartial("#modal-body-new-" + model, url);
    });
  return $("#new-form-submit-" + model)
    .off("click")
    .on("click", function () {
      return $("#new-form-" + model).submit();
    });
};

pjax_alert_cookie = "browser-suggest-alert-displayed";

pjax_alert_showtimes = 3;

pjaxAlert = function () {
  var displayed;
  if (!$.support.pjax) {
    displayed = getCookie(pjax_alert_cookie);
    if (displayed !== "0") {
      show_alert(I18n.t("use_browser_latest_version"));
      displayed =
        displayed === "" ? pjax_alert_showtimes : parseInt(displayed, 10) - 1;
      return setCookie(pjax_alert_cookie, displayed, 10);
    }
  }
};

refresh = function () {
  if (document.getElementById("spinner")) {
    if ($.support.pjax) {
      return $.pjax({
        url: window.location.pathname,
        container: "[data-pjax-container]",
        push: false,
      }).done(
              function(){
                control_remote_tab();

              }
            )
    } else {
      return (window.location.href = document.URL);
    }
  }
};

refresh_sw_download = function () {
  if ($.support.pjax && $(".sw-activating").length > 0) {
    $.pjax({
      url:
        window.location.pathname + "/software?status=" + new Date().getTime(),
      container: "[data-pjax-swupload]",
      push: false,
    });
    return setTimeout(refresh_sw_download, 30000);
  }
};

start_refresh_sw_download = function () {
  if ($(".sw-activating").length > 0) {
    return (window.timeout = setTimeout(refresh_sw_download, 30000));
  }
};

loader_init = function (obj) {
  $("#ultralink_software_doc_link").addClass("btn btn-default label-default");
  return $("#new-form-submit-ultralink-software").on("click", function () {
    return $(this)
      .closest(".modal")
      .find(".modal-body")
      .html(
        '<div class="spinner">' + I18n.t("activating_please_wait") + "</div>"
      );
  });
};

clickable_badge_init = function () {
  return $(".clickable-badge")
    .off("click")
    .on("click", function (e) {
      var container, params, type;
      e.preventDefault();
      params = {
        command: $(this).attr("data-command"),
        authenticity_token: $("meta[name=csrf-token]").attr("content"),
      };
      if ($(".clickable-badge").first().attr("user-pin") !== void 0) {
        params.user_pin = $(this).attr("user-pin");
      }
      type = $(this).attr("method");
      container = $(this).attr("data-pjax") || "[data-pjax-container]";
      $.pjax({
        type: type || "POST",
        url: $(this).attr("href"),
        container: container,
        push: false,
        data: params,
      });
      return false;
    });
};

remote_console_link_init = function () {
  return $("#remote_console_link")
    .off("click")
    .on("click", function (i) {
      if ($(this).parent().hasClass("disabled")) {
        return false;
      }
      return $(this).parent()
        .find("form")
        .submit();
    });
    return false;
  };


control_remote_tab = function () {
    if (document.getElementById("spinner") == null) {
      if($('#remote_console_tab').length > 0)
      {
        let online_interfaces = 0;
        let panel_offline = true;
        if(page_tag_exists('ultralinks_diagnostic_reports')){
          if (!$('.alert_text').text())
          {
            panel_offline = false;
          }
        }
        else{
        $('*[id*=uintf_]:visible').each(function(){
          if($(this).text().indexOf(I18n.t("online")) > -1)
          {
            online_interfaces++;
          }
        })
        if (online_interfaces > 0)
        {
            panel_offline = false;
          }
        }

        if (panel_offline)
        {
          $('#remote_console_tab').addClass('disabled');
          $('#remote_console_tab a').attr('data-original-title', I18n.t('device_offline'));
        }
        else
        {
          $('#remote_console_tab').removeClass('disabled');
          $('#remote_console_tab a').attr('data-original-title', '');
        }
      }
    }
};

var enable_refresh = function() {
  if ($("#message-area a.close").attr("data-refresh") !== "enabled") {
    $("#message-area a.close").attr("data-refresh", "enabled");
    return $("#message-area a.close").on('click', () => setTimeout(refresh, 30000));
  }
};

document_ready = function () {
  var crud_options;
  remote_console_link_init();
  crud_options = {
    "ultralink-software": {
      new: loader_init,
    },
    ultralink: {
      new: ultralink_new_init,
      edit: ultralink_edit_init,
      delete: ultralink_delete_init,
    },
  };
  init_datatable(crud_options);
  $(".carousel").each(function () {
    $(this).carousel({
      interval: false,
    });
    if (window.active_index !== void 0) {
      $(this).carousel(window.active_index);
    }
    return $(this).on("slid", function () {
      return (window.active_index = $(this).find(".active").index());
    });
  });
  if (document.getElementById("spinner")) {
    window.timeout = setTimeout(refresh, 3000);
  } else {
    if ($('div[data-pjax-container]').hasClass('disabled') && $('#remote_console_tab').length > 0)
    {
          control_remote_tab();
    }
    $("body").on(
      "change",
      "#download_job_ultralink_software_version_id",
      function () {
        var current_version,
          doc_present,
          firmware_version,
          firmware_version_name,
          software_ver_element,
          span_block,
          version_id;
        doc_present = $("#download_job_ultralink_software_version_id")
          .find("option:selected")
          .attr("doc-present-check");
        version_id = $("#download_job_ultralink_software_version_id")
          .find("option:selected")
          .attr("value");
        if (doc_present === "true") {
          $("#ultralink_software_doc_link").removeClass(
            "btn btn-default label-default"
          );
          $("#ultralink_software_doc_link").addClass("btn btn-success");
          $("#ultralink_software_doc_link").attr("disabled", false);
          $("#ultralink_software_doc_link").attr({
            target: "_blank",
            href:
              "/ultralink_software_versions/" +
              version_id +
              "/download_ultralink_document",
          });
        } else {
          $("#ultralink_software_doc_link").attr({
            target: "",
            href: "#",
          });
          $("#ultralink_software_doc_link").attr("disabled", true);
          $("#ultralink_software_doc_link").removeClass("btn btn-success");
          $("#ultralink_software_doc_link").addClass(
            "btn btn-default label-default"
          );
        }
        software_ver_element = $("#download_job_ultralink_software_version_id");
        current_version = software_ver_element.attr("data-current-version");
        firmware_version_name = $(
          "#download_job_ultralink_software_version_id option:selected"
        ).text();
        firmware_version = firmware_version_name.match(
          /[A-Z]\d{2}[A-Z]\d{6}-\d{1,2}/g
        );
        if (firmware_version === null) {
          firmware_version = firmware_version_name.match(
            /[A-Z]\d{2}-\d{1,2}.\.\d*/g
          );
        } else {
          if (
            firmware_version[0].match(/[A-Z]\d{2}[A-Z]\d{6}-\d{2}/g) === null
          ) {
            firmware_version[0] = firmware_version[0].replace(/-/, "-0");
          }
        }
        span_block = $("span.help-block");
        if (span_block) {
          span_block.remove();
        }
        if (current_version === firmware_version[0]) {
          $(software_ver_element).parent().parent().addClass("has-error");
          return $(software_ver_element).after(
            '<span class="help-block">' +
              I18n.t("firmware_upgrade_version_warning") +
              "</span>"
          );
        } else {
          return $(software_ver_element)
            .parent()
            .parent()
            .removeClass("has-error");
        }
      }
    );
    if (!$.support.pjax) {
      pjaxAlert();
    }
  }
  $(".network-status")
    .off("click")
    .on("click", function (e) {
      e.preventDefault();
      $("#network-status-dialog").modal({
        dynamic: true,
      });
      focus_modal_elem($("#network-status-dialog"));
      return false;
    });
  $("#registration-history")
    .off("click")
    .on("click", function (e) {
      e.preventDefault();
      $("#registration-history-dialog").modal({
        dynamic: true,
      });
      focus_modal_elem($("#registration-history-dialog"));
      return false;
    });
  $("#sw-history")
    .off("click")
    .on("click", function (e) {
      var url;
      e.preventDefault();
      url = $(this).attr("href") + "&" + new Date().getTime();
      $("#sw-history-spinner").show();
      $("#sw-history-dialog").modal({
        dynamic: true,
      });
      focus_modal_elem($("#sw-history-dialog"));
      window.xhr = loadPartial("#modal-body-sw-history", url, {
        success: function () {
          return true;
        },
      });
      return false;
    });
  $("#modal-body-network-status-close")
    .off("click")
    .on("click", function (e) {
      if (window.xhr && window.xhr.readstate !== 4) {
        window.xhr.abort();
      }
      return true;
    });
  $("#modal-body-registration-history-close")
    .off("click")
    .on("click", function (e) {
      if (window.xhr && window.xhr.readstate !== 4) {
        window.xhr.abort();
      }
      return true;
    });
  clickable_badge_init();
  $(".need-pin")
    .off("click")
    .on("click", function (e) {
      $("#enter_pin").modal({
        dynamic: true,
      });
      focus_modal_elem($("#enter_pin"));
      $(".new-pin-submit")
        .off("click")
        .on("click", function (i) {
          var pin;
          clickable_badge_init();
          pin = $(".user_pin", "#enter_pin").val();
          $(e.target).attr("user-pin", pin).trigger("click");
          $(".user_pin", "#enter_pin").val("");
          return $("#enter_pin").modal("hide");
        });
      return false;
    });
  $(".reset-pin-btn")
    .off("click")
    .on("click", function (e) {
      $(".panel-config-check").modal({
        dynamic: true,
      });
      focus_modal_elem($(".panel-config-check"));
      $(".panel-config-modal-title").text(
        $(this).attr("data-original-title")
      );
      $("#request_type").val(3);
      $("#confirmation_text").text(
        I18n.t("config_confirmation_text", {
          request_type: I18n.t("reset_pin"),
        })
      );
      $(".panel-config-submit")
        .off("click")
        .on("click", function (i) {
          return $(this)
            .closest(".modal")
            .find("form")
            .addHidden("commit", $(this).attr("data-value"))
            .submit();
        });
      return false;
    });
  $(".test-btn")
    .off("click")
    .on("click", function (e) {
      $(".panel-config-check").modal({
        dynamic: true,
      });
      focus_modal_elem($(".panel-config-check"));
      $(".panel-config-modal-title").text(
        $(this).attr("data-original-title")
      );
      $("#request_type").val(5);
      $("#confirmation_text").text(
        I18n.t("config_confirmation_text", {
          request_type: I18n.t("test_path"),
        })
      );
      $(".panel-config-submit")
      .off("click")
      .on("click", function (i) {
        return $(this)
          .closest(".modal")
          .find("form")
          .addHidden("commit", $(this).attr("data-value"))
          .submit();
        });
      return false;
    });
  $(".re-boot-btn")
    .off("click")
    .on("click", function (e) {
      $(".panel-config-check").modal({
        dynamic: true,
      });
      focus_modal_elem($(".panel-config-check"));
      $(".panel-config-modal-title").text(
        $(this).attr("data-original-title")
      );
      $("#request_type").val(1);
      $("#confirmation_text").text(
        I18n.t("config_confirmation_text", {
          request_type: I18n.t("reboot"),
        })
      );
      $(".panel-config-submit")
        .off("click")
        .on("click", function (i) {
          return $(this)
            .closest(".modal")
            .find("form")
            .addHidden("commit", $(this).attr("data-value"))
            .submit();
        });
      return false;
    });
  $(".tvr-reset-pw-btn")
    .off("click")
    .on("click", function (e) {
      $(".initiate-tvr-reset-pw").modal({
        dynamic: true,
      });
      focus_modal_elem($(".initiate-tvr-reset-pw"));
      $(".initiate-tvr-reset-pw-title").text(
        $(this).parent().attr("data-original-title")
      );
      $(".tvr-reset-pw-submit")
        .off("click")
        .on("click", function (i) {
          return $(this)
            .closest(".modal")
            .find("form")
            .addHidden("commit", $(this).attr("data-value"))
            .submit();
        });
      return false;
    });
  $(".tvr-webpage-access-btn")
    .off("click")
    .on("click", function (e) {
      $(".initiate-tvr-webpage-access").modal({
        dynamic: true,
      });
      $(".initiate-tvr-webpage-access-title").text(
        $(this).parent().attr("data-original-title")
      );
      $(".tvr-webpage-access-submit")
        .off("click")
        .on("click", function (i) {
          return $(this)
            .closest(".modal")
            .find("form")
            .addHidden("commit", $(this).attr("data-value"))
            .submit();
        });
      return false;
  });
  $(".tvr-firmware-notify-btn")
    .off("click")
    .on("click", function (e) {
      $(".initiate-tvr-firmware-notify").modal({
        dynamic: true,
      });
      $(".initiate-tvr-firmware-title").text(
        $(this).parent().attr("data-original-title")
      );
      $(".tvr-firmware-notify-submit")
        .off("click")
        .on("click", function (i) {
          return $(this)
            .closest(".modal")
            .find("form")
            .addHidden("commit", $(this).attr("data-value"))
            .submit();
        });
      return false;
  });
  $(".tvr-event-refresh-btn")
    .off("click")
    .on("click", function (e) {
      $(".initiate-tvr-event-refresh").modal({
        dynamic: true,
      });
      $(".initiate-tvr-event-refresh-title").text(
        $(this).parent().attr("data-original-title")
      );
      $(".tvr-event-refresh-submit")
        .off("click")
        .on("click", function (i) {
          return $(this)
            .closest(".modal")
            .find("form")
            .addHidden("commit", $(this).attr("data-value"))
            .submit();
        });
      return false;
    });
  $(".override-alert-btn")
    .off("click")
    .on("click", function (e) {
      $(".initiate-override-alert").modal({
        dynamic: true,
      });
      focus_modal_elem($(".initiate-override-alert"));
      $(".initiate-override-alert-title").text(
        $(this).parent().attr("data-original-title")
      );
      $(".override-alert-submit")
        .off("click")
        .on("click", function (i) {
          return $(this)
            .closest(".modal")
            .find("form")
            .submit();
        });
      return false;
    });
  return $(".panel-config-history-link")
    .off("click")
    .on("click", function (e) {
      var model_title;
      $(".panel-config-history").modal({
        dynamic: true,
      });
      focus_modal_elem($(".panel-config-history"));
      model_title = $(this).parent().attr("data-original-title");
      $(".panel-config-modal-title").text(model_title);
      return false;
    });
};

filter_vfnn_by_agency_id = function (mode) {
  var agency_id, vfnn_options, vfnn_select;
  agency_id = $(
    "#modal-body-" +
      mode +
      "-ultralink #ultralink_report_channel_branch_id option:selected"
  ).attr("data-agency-id");
  vfnn_select = $(
    "#modal-body-" + mode + "-ultralink #ultralink_report_channel_vfnn"
  );
  vfnn_options = $(
    "#modal-body-" + mode + "-ultralink #ultralink_report_channel_vfnn option"
  );
  $("#ultralink_report_channel_agency_id").val(agency_id)
  vfnn_options.each(function () {
    if ($(this).attr("data-agency-id") === agency_id) {
      return $(this).prop("selected", false).show().prop("disabled", false);
    } else if ($(this).val() !== "") {
      return $(this).prop("selected", false).hide().attr("disabled", true);
    }
  });
  if (agency_id) {
    vfnn_select.attr("readonly", false);
  } else {
    hide_select_options("#ultralink_report_channel_vfnn")
    hide_select_options("#ultralink_report_channel_format")
    return vfnn_select.trigger("change");
  }
  var site_type = $("#site_type").val();
  var recorder_formats = $("#new_ultralink").data('recorder-formats');
  var camera_formats   = $("#new_ultralink").data('camera-formats');
  device_type_formats(site_type, "ultralink_report_channel_format",
                                  recorder_formats, camera_formats )
  return vfnn_select.trigger("change");
};

ultralink_delete_init = function () {
  $("#extra-notice").empty();
  return $("#extra-notice").append(
    '<br /><div class="checkbox"><label><input type="checkbox" id="deactivate_sim" name="deactivate_sim" /> ' +
      I18n.t("deactivate_sim_card") +
      "</label></div>"
  );
};

show_pw = function () {
  $("#pass").attr("type", "text");
};
hide_pw = function () {
  $("#pass").attr("type", "password");
};

ultralink_edit_init = function () {
  filter_vfnn_by_agency_id("edit");
  $("#modal-body-edit-ultralink #ultralink_report_channel_branch_id").attr(
    "disabled",
    true
  );
  return $("#modal-body-edit-ultralink #ultralink_report_channel_vfnn").attr(
    "disabled",
    true
  );
};

//In non recorder ultralink form service grades filter out recorder service grades.
disable_recorder_sg = function (){
  if ($("#is_recorder").val() == "false") {
    ultralink_sg = $("#ultralink_report_channel_service_grade option");
    ultralink_sg.each(function () {
      if ($(this).attr("data-is-recorder") == "true") {
        $(this).hide().prop("disabled", true);
      } else {
        $(this).show().prop("disabled", false);
      }
    });
  }
}

ultralink_new_init = function () {
  //In non recorder ultralink form service grades filter out recorder service grades.
  if ($("#is_recorder").val() == "false") {
    ultralink_sg = $("#ultralink_report_channel_service_grade option");
    ultralink_sg.each(function () {
      if ($(this).attr("data-is-recorder") == "true") {
        $(this).hide().prop("disabled", true);
      } else {
        $(this).show().prop("disabled", false);
      }
    });
  }

  //For recorder ultralink, only filter self monitor central stations/dealers
  if ($("#is_recorder").val() == "true") {
    ultralink_branches = $("#ultralink_report_channel_branch_id option");
    ultralink_branches.each(function () {
      if( $(this).attr("data-is-self-monitoring") == "true" ) {
        $(this).show().prop("disabled", false);
      } else {
        $(this).hide().prop("disabled", true);
      }
    });
  }

  $("#ultralink_report_channel_service_grade").off("change").on('change', function() {
    if($(this).find(':selected').attr("data-is-lower-grade") == "true"){
      if ($(this).find(':selected').attr("data-is-recorder") == "true") {
        $(".ultralink_report_channel_vfnn").hide();
        $(".ultralink_report_channel_acctno").hide();
        $(".ultralink_report_channel_format").hide();
      }
      else
      {
        $(".ultralink_report_channel_vfnn").show();
        $(".ultralink_report_channel_acctno").show();
        $(".ultralink_report_channel_format").show();
      }
    } else {
      $(".ultralink_report_channel_vfnn").show();
      $(".ultralink_report_channel_acctno").show();
      $(".ultralink_report_channel_format").show();
    }
  });

  $("#ultralink_report_channel_vfnn").off("change").on('change', function() {
    const vfnn_protocol    = $("#ultralink_report_channel_vfnn").find("option:selected").attr("data-protocol");

    if (vfnn_protocol == undefined ){
      hide_select_options("#ultralink_report_channel_format")
    } else {
      $("#ultralink_report_channel_format").attr("readonly", false);
      $("#ultralink_report_channel_format option").slice(1).each(function() {
        const format_option = $(this);
        const option_protocols = format_option.attr("data-protocols");
        if ((option_protocols !== undefined) && (option_protocols.indexOf(vfnn_protocol) >= 0)) {
          return format_option.prop("selected", false).show().prop("disabled", false);
        } else {
          return format_option.prop("selected", false).hide().attr("disabled", true);
        }
      });
        var recorder_formats = $("#new_ultralink").data('recorder-formats');
        var camera_formats   = $("#new_ultralink").data('camera-formats');
        device_type_formats($("#site_type").val(), "ultralink_report_channel_format",
                                    recorder_formats, camera_formats )
    }
  });

  $('#ultralink_sid').on('blur', function() {
    var usid = $('#ultralink_sid').val();
    if (usid !== "" && usid.length == 12) {
      $.ajax({
       url: "/ultralinks/get_device_details",
        type: "GET",
        data: { sid: usid },
        success(data, status, xhr) {
          if (!sessionExpired(xhr)) {
            if ((data["device_type"] === 1) || (data["device_type"] === 2 )) {
              $(".rpt-channel").css("display", "none");
              $('#is_recorder').val(true);
            } else {
              $(".rpt-channel").css("display", "block");
              $('#is_recorder').val(false);
            }
            show_device_type_label(data["device_type"],"ultralink_sid");
            $('#site_type').val(data["device_type"]);
            $("#ultralink_report_channel_service_grade").prop('selectedIndex',0);
            $("#ultralink_report_channel_branch_id").prop('selectedIndex',0);
            $("#ultralink_report_channel_service_grade option:not(:first-child)").remove();
            for (i = 0; i <= data["service_grades"].length - 1; i++) {
              var option_att = data["service_grades"][i][2]
              var option_val = data['service_grades'][i][0]
              $('<option>').attr({
                'data-is-lower-grade': option_att['data-is-lower-grade'],
                'data-is-recorder': option_att['data-is-recorder'],
                'data-is-camera': option_att['data-is-camera'],
                'data-standard-fcode': option_att['data-standard-fcode'],
                value: option_val
              }).text(option_val).appendTo('#ultralink_report_channel_service_grade');
            }

            $("#ultralink_report_channel_branch_id").prop('selectedIndex', 0);
            $("#ultralink_report_channel_branch_id").trigger('change');

            hide_select_options("#ultralink_report_channel_vfnn")
            hide_select_options("#ultralink_report_channel_format")

            $("#ultralink_report_channel_service_grade").trigger('change');
            camera_agency_filter($('#site_type').val(), $("#ultralink_report_channel_branch_id option"))
            disable_recorder_sg();

            prepare_agency_sg("new");
          }
        }
      });
    }
  });

  // Checking Acctno validation
  initAcctnoValidation("new_ultralink", "ultralink_report_channel_acctno", "#new-form-submit-ultralink")

  $("#modal-body-new-ultralink #ultralink_report_channel_branch_id")
    .off("change")
    .on("change", function () {
      prepare_agency_sg("new");
      filter_vfnn_by_agency_id("new");
      return hide_select_options("#ultralink_report_channel_format")
    });
  $("#modal-body-new-ultralink #ultralink_report_channel_branch_id")
    .val("")
    .trigger("change");
  return $("#ultralink_report_channel_channel option:not([disabled])")
    .first()
    .attr("selected", "selected");
};

var prepare_agency_sg = function(mode) {
  const is_self_monitoring = $("#modal-body-new-ultralink #ultralink_report_channel_branch_id option:selected").attr("data-is-self-monitoring");
  var site_type = $("#site_type").length > 0 ? $("#site_type").val() : "0";
  filter_service_grade_by_smonitoring(mode, "ultralink", is_self_monitoring, site_type, "ultralink_report_channel")
}

var init_datatable = function (crud_options) {
  var ultralinkTable;
  ultralinkTable = $("#ultralinks-table");
  if (ultralinkTable.length === 1) {
    return dt.initDatatable(ultralinkTable, {}, crud_options);
  } else {
    return init_object_crud(crud_options);
  }
};

jQuery(function () {
  run_ontag("ultralinks", function () {
    $(".ulralink-version-doc").on("shown", function (e) {
      $("#ultralink_software_doc_link").attr({
        target: "",
        href: "#",
      });
      return $("#ultralink_software_doc_link").attr("disabled", true);
    });

    new Clipboard(".btn-copy-reset-pw", {
      text: function(trigger) {
        return $("#pass").val();
      }
    });

    document_ready();
    if ($("#created").length) {
      setTimeout(refresh, 30000);
    }
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      $(document).pjax("a[data-pjax]");
      $(document).pjax("span[data-pjax]");
      $(document).on("pjax:end", document_ready);
      return start_refresh_sw_download();
    }
  });
  return run_ontag("ultralinks_edit_remote_control", function () {
    return $('input[name="remote_control_user_inputs[control_type]"]')
      .off("click")
      .on("click", function () {
        if ($(this).val() === "1") {
          return $(".input-output").hide();
        } else {
          return $(".input-output").show();
        }
      });
  });
});
