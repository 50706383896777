/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// TODO: Refactor

const initDownload = () => $('a.customer-export-download').off('click').on('click', function() {
  $.fileDownload($(this).attr("href"), {
    data: {format: 'xlsx'},
    successCallback(url) {
      show_alert(I18n.t("customer_download_successful"));
      return enable_refresh();
    }
    ,
    failCallback(url) {
      return show_error(I18n.t("customer_download_failed"));
    }
  });
  return false;
});

const customer_delete_init = function(button) {
  $("#default-notice").empty();
  var noticeText = I18n.t("delete_customer_prompt", {name: button.attr("data-customer-name")}) + "<br /><br />" + I18n.t("customer_delete_remote_devices_warning");
  $("#default-notice").append(noticeText);
  $("#extra-notice").empty();
  if (button.attr("data-mode") === "1") {
    $("#extra-notice").append("<h4>" + I18n.t("warn_external_data_master") + "</h4>");
  }
  return $("#extra-notice").append('<br /><div class="checkbox"><label><input type="checkbox" id="deactivate_sim" name="deactivate_sim" /> ' + I18n.t("deactivate_sim_card") + '</label></div>');
};

const customer_edit_init = function() {
  country_select_init("customer", "#customer_country_code");
  $("#customer_country_code").trigger('change');
  return $("#customer_country_code").on('change', () => //update_timezones("customer_ultralink", "#customer_country_code")
  //update_service_grades("customer_ultralink_report_channel", "#customer_country_code")
  //update_companies_list("customer_ultralink_report_channel", "#customer_country_code")
  filter_vfnn_by_agency_id("customer", ""));
};

const customer_new_init = function() {
  initToolTip();
  if($("#can_access_wizard").val() == 'true'){
    $("#new-form-next-user-request").show();
    $("#new-form-previous-user-request").hide();
    $("#new-form-submit-user-request").hide();
  } else {
    $("#new-form-next-user-request").hide();
    $("#new-form-previous-user-request").hide();
    $("#new-form-submit-user-request").show();
      $("#modal-body-new-customer").on("keyup blur", "#customer_ultralink_sid", function(){
      var usid = $('#customer_ultralink_sid').val();
      panel_company_autocomplete = $("#customer_ultralink_report_channel_branch_id_autocomplete");
      if (usid !== "" && usid.length == 12) {
        $.ajax({
         url: "/ultralinks/get_device_details",
          type: "GET",
          data: { sid: usid },
          success(data, status, xhr) {
            if (!sessionExpired(xhr)) {
              $('#site_type').val(data["device_type"]);
              if (data["device_type"] == 1){
                $('#is_recorder').val(true);
              } else {
                $('#is_recorder').val(false);
              }
              var site_type = $('#site_type').val();
              //Pass the site type to data base query and filter only selfmon cms to camera
              var url_path = $('#customer_ultralink_report_channel_branch_id_autocomplete').attr('data-autocomplete').split('?');
              var url = url_path[0] + "?device_type=" + site_type;
              $('#customer_ultralink_report_channel_branch_id_autocomplete').attr('data-autocomplete', url);

              show_device_type_label(data["device_type"],"customer_ultralink_sid");
              $("#customer_ultralink_report_channel_service_grade option:not(:first-child)").remove();
              for (i = 0; i <= data["service_grades"].length - 1; i++) {
                var option_att = data["service_grades"][i][2]
                var option_val = data['service_grades'][i][0]
                $('<option>').attr({
                  'data-is-lower-grade': option_att['data-is-lower-grade'],
                  'data-is-recorder': option_att['data-is-recorder'],
                  'data-is-camera': option_att['data-is-camera'],
                  'data-standard-fcode': option_att['data-standard-fcode'],
                  value: option_val
                }).text(option_val).appendTo('#customer_ultralink_report_channel_service_grade');
              }

              $("#customer_ultralink_report_channel_service_grade").trigger('change');
              $("#customer_ultralink_report_channel_agency_id").val('');
              $("#customer_ultralink_report_channel_branch_id").val('');
              //Make CMS Input to empty on change of SID device type lookup.
              $("#customer_ultralink_report_channel_branch_id_autocomplete").val('').focus();

              hide_select_options("#customer_ultralink_report_channel_vfnn");
              hide_select_options("#customer_ultralink_report_channel_format");

              change_autocomplete_placeholder(panel_company_autocomplete,
                            I18n.t("autocomplete_branch_cms_company_message"),
                            I18n.t('agency')+"/"+I18n.t('branch'))

              if (site_type == 1 || site_type == 2) {
                $("#customer_ultralink_report_channel_service_grade").prop('selectedIndex',0);
                recorder_input_fields("system");
              } else {
                $("#customer_ultralink_report_channel_service_grade").prop('selectedIndex',0);
                if($(".sg_features_block").length && $(".sg_features_block").is(':visible')) {
                  $(".sg_features_block").hide();
                }
                sg_filter_type(site_type);
                panel_input_fields();
              }
            }
          }
        });
      }
    });

    // Service grades are not loading if customer form has earlier session sid for admin
    // Here triggering the sid input field to load the service grades
    var customer_value = $("#customer_ultralink_sid").val();
    if (customer_value){
      $("#customer_ultralink_sid").blur();
    }
  }

  sg_filter_type(0);
  const customer_model = "customer";
  country_select_init(customer_model, "#customer_country_code");
  $("#customer_country_code").trigger('change');
  $("#customer_country_code").on('change', () => //update_timezones("customer_ultralink", "#customer_country_code")
  //update_service_grades("customer_ultralink_report_channel", "#customer_country_code")
  //update_companies_list("customer_ultralink_report_channel", "#customer_country_code")
  filter_vfnn_by_agency_id("customer", ""));
  filter_vfnn_by_agency_id("customer", "");
  autocomplete_label = "autocomplete_label";
  oh_reporting_input_fields("customer_ultralink_report_channel", "customer");
  panel_company_autocomplete = $("#customer_ultralink_report_channel_branch_id_autocomplete");
  panel_company_autocomplete.off('keyup').on('keyup', (function(_this) {
    return function() {
      return toggle_company_placeholder_message($("#"+autocomplete_label), panel_company_autocomplete);
    };
  })(this));
  panel_company_autocomplete.on('railsAutocomplete.select', function(event, data) {
    $("#customer_ultralink_report_channel_vfnn").attr("readonly", true);
    if (data.item.label === I18n.t('no_results_found')) {
      panel_company_autocomplete.val("");
      toggle_company_placeholder_message($("#"+autocomplete_label), panel_company_autocomplete);
      return false;
    } else {
      company_selection_callback(data, customer_model);
      hide_select_options("#customer_ultralink_report_channel_format")
    }
  });
  restrict_addons_for_core("customer");

  filter_format_by_vfnn(customer_model);

  // Validate account number for system admin and CS users site provision requests
  initAcctnoValidation("new_customer", "customer_ultralink_report_channel_acctno", "#new-form-submit-customer");

  //TODO: Generic feature_list implementation for user request request/report channel & sites
  return create_sg_feature_list("#customer_ultralink_report_channel_service_grade", "#site_type");
};

var filter_vfnn_by_agency_id = function(model, agency_id) {
  const vfnn_select   = $("#modal-body-new-" + model + " #customer_ultralink_report_channel_vfnn");
  const vfnn_options  = $("#modal-body-new-" + model + " #customer_ultralink_report_channel_vfnn option");
  let selected_vfnn = "";

  vfnn_options.each(function() {
    if ($(this).val() === "") {
      //$(this).attr("selected", true)
    } else if (Number($(this).attr("data-agency-id")) === Number(agency_id)) {
      if ($(this).prop("selected")) {
        selected_vfnn = $(this).val();
      }
      return $(this).prop("selected", false).show().prop("disabled", false);
    } else {
      return $(this).prop("selected", false).hide().attr("disabled", true);
    }
  });

  if (agency_id) {
    vfnn_select.attr("readonly", false);
  } else {
    vfnn_select.attr("readonly", true);
  }

  return vfnn_select.val(selected_vfnn).trigger('change');
};

var filter_format_by_vfnn = function(model) {
  const modal            = `#modal-body-new-${model}`;
  const vfnn_select      = $(`${modal} #customer_ultralink_report_channel_vfnn`);
  const format_select    = $(`${modal} #customer_ultralink_report_channel_format`);
  const format_options   = $(`${modal} #customer_ultralink_report_channel_format option`);
  const preferred_format = $(`${modal} #customer_ultralink_report_channel_preferred_report_channel_format`);
  const notification_group = $(`${modal} #notification_group_container`);
  const allow_notification = $(`${modal} #customer_ultralink_report_channel_notification_group_enabled`);
  const master_unit = $(`${modal} .customer_ultralink_report_channel_master_unit`);
  const master_unit_checkbox = $(`${modal} #customer_ultralink_report_channel_master_unit`);
  const form              = $(`${modal} form`);
  const special_vfnn = form.data('special-vfnn');
  const recorder_formats = form.data('recorder-formats');
  const camera_formats = form.data('camera-formats');
  let special_vfnn_array = [];
  if (special_vfnn) {
    special_vfnn_array = `${special_vfnn}`.split(",");
  }

  vfnn_select.off("change").on('change', function() {
    const vfnn_protocol    = vfnn_select.find("option:selected").attr("data-protocol");
    //is_notification_enabled = vfnn_select.find("option:selected").attr("data-notification-group")
    let has_formats = false;
    const master_unit_val = vfnn_select.find("option:selected").attr("data-master-unit");
    const selected_vfnn = vfnn_select.find("option:selected").val();

    format_options.slice(1).each(function() {
      const format_option = $(this);
      const option_protocols = format_option.attr("data-protocols");
      if ((option_protocols !== undefined) && (option_protocols.indexOf(vfnn_protocol) >= 0)) {
        format_option.prop("selected", false).show().prop("disabled", false);
        return has_formats = true;
      } else {
        return format_option.prop("selected", false).hide().attr("disabled", true);
      }
    });
    var site_type = $("#site_type").val();
    if (vfnn_protocol) {
      format_select.attr("readonly", false);
      allow_notification.prop('checked', false);
      if (site_type == "0" && Array.from(special_vfnn_array).includes(selected_vfnn)) {
        notification_group.show();
        if (master_unit_val) {
          master_unit.hide();
          master_unit_checkbox.prop('checked', false);
        } else {
          master_unit.show();
          master_unit_checkbox.prop('checked', false);
        }
      } else {
        notification_group.hide();
        allow_notification.prop('checked', false);
        master_unit_checkbox.prop('checked', false);
      }

      device_type_formats(site_type, "customer_ultralink_report_channel_format",
                                  recorder_formats, camera_formats )
      if (has_formats) {
        let option = format_select.find(`option[value='${preferred_format.val()}']:not([disabled])`);
        if (!(option.length > 0)) {
          option = format_select.find("option:not([disabled])");
        }
      } else {
        format_select.find("option").first().show().prop("disabled", false)
                  .prop("selected", true);
      }
      return format_select.trigger('change');

    } else {
      notification_group.hide();
      allow_notification.prop('checked', false);
      master_unit_checkbox.prop('checked', false);
      hide_select_options("#customer_ultralink_report_channel_format");
    }

  });
  vfnn_select.trigger('change');
  return load_company_defaults()
};

var refresh = () => $.get('/customers/download_customer_export.json', function(obj, status, xhr) {
  if (sessionExpired(xhr)) { return; }
  const {
    download_path
  } = obj;
  if (download_path !== undefined) {
    show_alert('<a class="customer-export-download" href="' +
               download_path +
               '">' + I18n.t("customer_export_completed") +
               '</a>');
    initDownload();
    return;
  }

  if (download_path === undefined) {
    return setTimeout(refresh, 30000);
  }
});

add_next_previous_btns = function() {
  if($("#can_access_wizard").val() == 'true')
  {
    var request_type = $("#user_request_request_type").val();
    // The Next and Previous buttons and its on click functionality derived in this method.
    if (request_type == "1"){
      var numberOfSteps = $('.f1-steps .f1-step').length;

      // Based on size of steps reducing the width of each step navigation div
      // Needs to move with CSS
      if (numberOfSteps > 4){
        $('.f1-step').css({
            'position': 'relative',
            'float': 'left',
            'width': '20%' ,
            'padding': '0 5px'
        });
      } else {
        $('.f1-step').css({
            'position': 'relative',
            'float': 'left',
            'width': '25%',
            'padding': '0 5px'
        });
      }

      $("#new-form-next-user-request").show();
      $("#new-form-previous-user-request").hide();
      $("#new-form-submit-user-request").hide();
      // When user clicks on Next button logic
      $('#new-form-next-user-request').off("click").on("click", function (e) {
        var classNames = [];
        var stepClasses = $('[class*=step_0]').not('.hidden-element');
        stepClasses.each(function() {
          classNames.push($(this).attr("class"));
        });
        var activeClass = classNames[0].split(" ");

        if(validateActiveForm(activeClass[0])){
          var progress_line = $(".f1-steps").find('.f1-progress-line');
          var current_active_step = $(".f1-steps").find('.f1-step.active');
          $('.' + activeClass.join(".")).fadeOut(400, function() {
            next_class = current_active_step.removeClass('active').addClass('activated').next();
            // Check if next parent div having display non class, then move next class and add active
            if(next_class.css('display') !== 'none'){
              // check if next class having display not none
              next_class.addClass('active')
            } else {
              next_class.next().addClass('active')
            }
            progress_bar(progress_line, 'right');
            $("#new-form-previous-user-request").show();
            $('.' + activeClass[0]).addClass('hidden-element');
            $('.' + activeClass[0]).removeClass('active');
            var nextStepElement  = $('.' + activeClass[0]).next('[class^="step_"]');
            if(next_class.css('display') == 'none'){
              nextStepElement = nextStepElement.next()
            }
            // If current class contains last_step string system shold show submit button
            current_active = nextStepElement.attr("class")
            if(current_active.includes('last_step') ){
              $("#new-form-submit-user-request").show();
              $("#new-form-next-user-request").hide();
            } else {
              $("#new-form-submit-user-request").hide();
              $("#new-form-next-user-request").show();
            }

            var currentClass = nextStepElement.attr("class").split(" ")[0];
            $('.' + currentClass).removeClass('hidden-element');
            $('.' + currentClass).addClass('active');
            $('.' + currentClass).removeAttr('style');

            if (currentClass == "step_02"){
              if ($(".plan_helper_txt").text() === ""){
                service_level_url = $("#service_level_url").val();
                $('#customer_ultralink_subscription_plan').
                  after("<a class='plan_helper_txt' href ='"
                    + service_level_url + "' title ='" + I18n.t('subscription_plan_help')
                    + "' target='_blank'>&nbsp;<i class='fa fa-question-circle fa-1x'></a>");
              }
            }
            if (currentClass == "step_03"){
              var recorder_formats = $("#new_user_request").data('recorder-formats');
              var camera_formats = $("#new_user_request").data('camera-formats');
              var vfnn_select      = $("#customer_ultralink_report_channel_vfnn");
              var format_select    = $("#customer_ultralink_report_channel_format");
              var format_options   = $("#customer_ultralink_report_channel_format option");

              let has_formats = false;

              $("#customer_ultralink_report_channel_vfnn").off("change").on('change', function() {
                const vfnn_protocol     = vfnn_select.find("option:selected").attr("data-protocol");
                if (vfnn_protocol === undefined) {
                  hide_select_options("#customer_ultralink_report_channel_format");
                } else {
                  format_options.slice(1).each(function() {
                    const format_option = $(this);
                    const option_protocols = format_option.attr("data-protocols");
                    if ((option_protocols !== undefined) && (option_protocols.indexOf(vfnn_protocol) >= 0)) {
                       format_option.prop("selected", false).show().prop("disabled", false);
                       return has_formats = true;
                    } else {
                      return format_option.prop("selected", false).hide().attr("disabled", true);
                    }
                  });

                  var site_type = $("#site_type").val();
                  device_type_formats(site_type, "customer_ultralink_report_channel_format",
                                              recorder_formats, camera_formats )
                  format_select.attr("readonly", false);
                }
              });
              load_company_defaults()
            }
            if (currentClass == "step_04"){
              if ($("#customer_ultralink_subscription_plan").val() == "Core Plus") {
                subscription = []
                $('#customer_ultralink_report_channel_fault_reporting_time option').each(function(){
                  if($(this).attr("data-subscription") == 'core_plus'){
                    subscription.push($(this).attr("data-connectivity"))
                  }
                });
                $.each(subscription, function(index, element) {
                  if(element === 'cellular') {
                    $("#cellular_id").show();
                    return false; //Return out of loop if element has cellular for core plus.
                  } else {
                    $("#cellular_id").hide();
                  }
                });
              } else{
                $("#cellular_id").show();
              }
              $('#ethernet').change(function(){
                $('#customer_ultralink_report_channel_fault_reporting_time').prop('selectedIndex', 0);
              })

              $('#cellular').change(function(){
                $('#customer_ultralink_report_channel_fault_reporting_time').prop('selectedIndex', 0);
              })
            }

            // Load Add-Ons basd on previous
            if (currentClass == "step_05"){
              var device_type = $('#site_type').val();
              load_sg_addon_for_wizard(device_type);
            }
          });
        }
      });

      $("#user_request_agency_id").off("change").on('change', function() {
        prepare_agency();
        const branch_id = $("#user_request_agency_id option:selected").attr("data-branch-id");
        $("#customer_ultralink_report_channel_branch_id").val(branch_id);
      });

      $(".customer_ultralink_subscription_plan").off("change").on('change', function() {
        if($("#site_type").val() == 0){
          // If selected device as panel and it has custom sg then hide connectivity tab
          if($(this).find(':selected').attr("data-is-custom-sg") == 'true'){
            fcode = $(this).find(':selected').attr("data-standard-fcode")

            if ($(this).find(':selected').attr("data-is-lower-grade") == 'true'){
              //show only self mon cms when lower service grade for custom service grades
              camera_agency_filter('2', $("#user_request_agency_id option"));
            } else {
              camera_agency_filter($("#site_type").val(), $("#user_request_agency_id option"));
            }

            hide_wizard_step(I18n.t('connectivity'))
            $("#custom_sg").val($(this).find(':selected').val());
            $("#fcode").val(fcode);
            $(".sg_features_block").show();
            refresh_feature_list("#site_type",fcode);
            return hideAddonResetFeatures();
          } else {
            show_wizard_step(I18n.t('connectivity'))
          }
        }

        // On change of subscription plan reset the selected connectivity check box
        // And reset Fault reporting time as well.
        $('#cellular').prop('checked', false);
        $('#ethernet').prop('checked', false);
        $('#customer_ultralink_report_channel_fault_reporting_time').prop('selectedIndex', 0);
        $(".filter_service_grades").hide();

        // Clear acctno if user comes back and changes subscription plan
        $("#customer_ultralink_report_channel_acctno").val('')
        $(".customer_ultralink_report_channel_acctno").removeClass("focused")

        if( ($("#site_type").val() == 1) || ($("#site_type").val() == 2) ){
          $("#custom_sg").val($(this).find(':selected').val());
        }

        if($(this).find("option:selected").val() == "") {
          if (!($('.customer_ultralink_subscription_plan').hasClass('has-error'))) {
            $('.customer_ultralink_subscription_plan').addClass(" has-error");
            $('.plan_helper_txt').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
          }
        } else {
          $('.plan_helper_txt').parent().find('.help-block').remove();
          $('#customer_ultralink_subscription_plan').parent().removeClass("has-error");

          // If selected subscription plan is "Core" then show only selfMon CMS.
          // If selected subscription plan is non-Core then show all the CMS.
          // If selected subscription plan is "Core" then fault reporting time should hide
          branch_details = $("#user_request_agency_id option");
          if($(this).find("option:selected").val() == "Core"){
            $(".customer_ultralink_report_channel_fault_reporting_time").hide();
            branch_details.each(function () {
              var isEmptyValue = $(this).val() === "";
              if (isEmptyValue){
                $(this).prop("selected", true);
              }
              if( $(this).attr("data-is-self-monitoring") == "true" ) {
                $(this).show().prop("disabled", false);
              } else if (!(isEmptyValue)) {
                $(this).hide().prop("disabled", true);
              }
            });
          } else {
            $(".customer_ultralink_report_channel_fault_reporting_time").show();

            branch_details.each(function () {
              if ($(this).val() === ""){
                $(this).prop("selected", true);
              }
              $(this).show().prop("disabled", false);
            });
          }
          prepare_agency();
        }
      });

      $("#filter_service_grades").off("change").on('change', function() {
        if (!($(this).val() === '')){
          var fcode = $(this).find(':selected').attr("data-standard-fcode");
          var service_grade = $(this).val();

          $("#custom_sg").val(service_grade);
          $("#fcode").val(fcode);
          $(".sg_features_block").show();
          return refresh_feature_list("#site_type", fcode)
        }
      });

      $("#customer_ultralink_report_channel_fault_reporting_time").off("change").on('change', function() {
        if ($("#site_type").val() == 0 ) {
          var device_type = $('#site_type').val();
          var subscription_plan = $("#customer_ultralink_subscription_plan").val();
          var ethernetChecked = $('#ethernet').is(':checked');
          var cellularChecked = $('#cellular').is(':checked');
          var fault_reporting_time = ""
          if (!($("#customer_ultralink_subscription_plan").val() == "Core")) {
            fault_reporting_time =  $(this).val();
          }
          //Remove and Add cont be blank msg while selecting and un-select the fault reporting time.
          if ($(this).val() === '' || $(this).val() == null) {
            $('#customer_ultralink_report_channel_fault_reporting_time').prop('selectedIndex', 0);
            if (!($('.customer_ultralink_report_channel_fault_reporting_time').hasClass('has-error'))) {
              $('.customer_ultralink_report_channel_fault_reporting_time').addClass(" has-error");
              $('#customer_ultralink_report_channel_fault_reporting_time').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
            }
          } else {
            $('#customer_ultralink_report_channel_fault_reporting_time').parent().find('.help-block').remove();
            $('#customer_ultralink_report_channel_fault_reporting_time').parent().removeClass("has-error");
          }
          if (!(fault_reporting_time === '') && (ethernetChecked || cellularChecked))
          {
            if(($("#customer_ultralink_subscription_plan").find(':selected').attr("data-is-custom-sg") == "false")){
              $.ajax({
               url: "/ultralinks/generate_service_grade",
                type: "GET",
                data: { device_type: device_type,
                        subscription_plan: subscription_plan,
                        ethernetChecked: ethernetChecked,
                        cellularChecked: cellularChecked,
                        fault_reporting_time: fault_reporting_time },
                success(data, status, xhr) {
                  if (!sessionExpired(xhr)) {
                    if(data['size'] > 1) {
                      var select = $('#filter_service_grades');
                      $('.filter_service_grades').show();
                      select.empty();
                      select.prepend($('<option>', {
                        value: '',
                        text: I18n.t("filter_service_grades")
                      }));

                      $.each(data["service_grade"], function(key, value) {
                        select.append($('<option>', {
                          'data-standard-fcode': value,
                          value: key,
                          text: key
                        }));
                      });

                      if ($('.filter_service_grades').is(':visible')){
                        if($('.filter_service_grades').val() === ''){
                          if (!($('.filter_service_grades').hasClass('has-error'))) {
                            $('.filter_service_grades').addClass(" has-error");
                            $('#filter_service_grades').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
                          }
                        }
                        else
                        {
                          $('#filter_service_grades').parent().find('.help-block').remove();
                          $('#filter_service_grades').parent().removeClass("has-error");
                        }
                      }
                    } else {
                      $('.filter_service_grades').hide();
                      $("#custom_sg").val('');
                      $("#fcode").val('');
                      var service_grade = ""
                      var fcode = ""
                      $.each(data["service_grade"], function(key, value) {
                        service_grade = key
                        fcode = value
                      });
                      $("#custom_sg").val(service_grade);
                      $("#fcode").val(fcode);
                      $(".sg_features_block").show();
                      hasError = true
                      refresh_feature_list("#site_type",fcode);
                      return hideAddonResetFeatures();
                    }
                  }
                }
              });
            }
          }
        }
      });

      // When user clicks on Back button logic
      $('#new-form-previous-user-request').off("click").on("click", function (e) {
        var progress_line = $(".f1-steps").find('.f1-progress-line');
        var stepClasses = $('[class*=step_0]').not('.hidden-element');
        var classNames = [];
        stepClasses.each(function() {
          classNames.push($(this).attr("class"));
        });
        var activeClass = classNames[0].split(" ");
        var current_active_step = $(".f1-steps").find('.f1-step.active');
        $('.' + activeClass.join(".")).fadeOut(400, function() {
          prev_class = current_active_step.removeClass('active').prev()
          if(prev_class.css('display') !== 'none'){
            // check if prev class having display not none
            prev_class.removeClass('activated').addClass('active');
          } else {
            prev_class.prev().removeClass('activated').addClass('active');
          }

          progress_bar(progress_line, 'left');
          $('.' + activeClass[0]).addClass('hidden-element');
          $('.' + activeClass[0]).removeClass('active');
          var prevStepElement  = $('.' + activeClass[0]).prev('[class^="step_"]');

          if(prev_class.css('display') == 'none'){
            prevStepElement = prevStepElement.prev()
          }
          var preClass = prevStepElement.attr("class").split(" ")[0];

          // If current class contains last_step string system shold show submit button
          current_active = prevStepElement.attr("class")
          if(current_active.includes('last_step') ){
            $("#new-form-submit-user-request").show();
            $("#new-form-next-user-request").hide();
          } else {
            $("#new-form-submit-user-request").hide();
            $("#new-form-next-user-request").show();
          }

          $('.' + preClass).removeClass('hidden-element');
          $('.' + preClass).removeAttr('style');
          $('.' + preClass).addClass('active');
          if (preClass == "step_01") {
            $("#new-form-previous-user-request").hide();
            $("#new-form-next-user-request").show();
          }else {
            $("#new-form-previous-user-request").show();
            $("#new-form-next-user-request").show();
          }
        });
      })

    } else {
      $("#new-form-next-user-request").hide();
      $("#new-form-previous-user-request").hide();
    }

    $('input[type="checkbox"][name="user_request[connectivity]"]').change(function() {
      updateSelectOptions($(this).attr('id'));
    })
  } else {
    $("#new-form-next-user-request").hide();
    $("#new-form-previous-user-request").hide();
    $("#new-form-submit-user-request").show();
  }
}

prepare_agency = function() {
  var agency_select = $("#user_request_agency_id");
  agency_select.off('change').on('change', (function(_this) {
    return function() {
      var agency_id, selected_branch, selected_vfnn, is_self_monitoring, site_type;
      var vfnn_select      = $("#customer_ultralink_report_channel_vfnn");
      var preferred_vfnn   = $("#customer_ultralink_report_channel_preferred_report_channel_vfnn");
      agency_id = agency_select.val();
      is_self_monitoring = agency_select.find("option:selected").attr("data-is-self-monitoring");
      var site_type = $("#site_type").val();
      set_branch();
      selected_vfnn = "";
      selected_branch = "";
      $("#customer_ultralink_report_channel_vfnn option").each(function() {
        var vfnn_option;
        vfnn_option = $(this);
        if (vfnn_option.val() === "") {

        } else if (vfnn_option.attr("data-agency-id") === agency_id) {
          if ($(this).prop("selected")) {
            selected_vfnn = $(this).val();
          }
          return vfnn_option.prop("selected", false).show().prop("disabled", false);
        } else {
          return vfnn_option.prop("selected", false).hide().attr("disabled", true);
        }
      });
      if ($("#customer_ultralink_report_channel_branch_id").prop('type') !== 'hidden') {
        $("#customer_ultralink_report_channel_branch_id option").each(function() {
          var branch_option;
          branch_option = $(this);
          if (branch_option.attr("data-agency-id") === agency_id) {
            if ($(this).prop("selected")) {
              selected_branch = $(this).val();
            }
            return branch_option.prop("selected", false).show().prop("disabled", false);
          } else {
            return branch_option.prop("selected", false).hide().attr("disabled", true);
          }
        });
        $("#customer_ultralink_report_channel_branch_id").val(selected_branch).trigger('change');
      }
      if (agency_id === "") {
        vfnn_select.attr("readonly", true);
        $("#customer_ultralink_report_channel_branch_id").attr("readonly", true);
      } else {
        vfnn_select.attr("readonly", false);
        $("#customer_ultralink_report_channel_branch_id").attr("readonly", false);
      }

      let vfnn_option = vfnn_select.find("option[value='"+preferred_vfnn.val()+"']:not([disabled])");

      if(vfnn_option.length > 0){
        vfnn_select.val(preferred_vfnn.val());
      }
      filter_service_grade_by_smonitoring("new", "user-request", is_self_monitoring, site_type, "customer_ultralink_report_channel")
      $("#customer_ultralink_report_channel_vfnn").trigger('change');
    };
  })(this));
  return $("#user_request_agency_id").trigger('change');
};
set_branch = function() {
  var branch_id;
  branch_id = $("#user_request_agency_id").find("option:selected").attr("data-branch-id");
  return $("#customer_ultralink_report_channel_branch_id").val(branch_id);
};

function updateSelectOptions(connectivity) {
  var checked_count = $('input[name="user_request[connectivity]"]:checked').length
  var subscription_plan = $("#customer_ultralink_subscription_plan").val();

  var subscription_plan_txt = ""
  if(subscription_plan == "Core Plus"){
    subscription_plan_txt = 'core_plus'
  } else if (subscription_plan == "Enhance") {
    subscription_plan_txt = 'enhance'
  }

  if (checked_count == 0) {
    $("#customer_ultralink_report_channel_fault_reporting_time option").slice(1).each(function () {
      $(this).show().prop("disabled", false);
    })
  } else if (checked_count > 1) {
    $("#customer_ultralink_report_channel_fault_reporting_time option").slice(1).each(function () {
      if( subscription_plan_txt === $(this).attr("data-subscription") && $(this).attr("data-connectivity").includes('dualpath') ) {
        $(this).show().prop("disabled", false);
      } else {
        $(this).hide().prop("disabled", true);
      }
    });
  } else if (checked_count <= 1 ) {
    $("#customer_ultralink_report_channel_fault_reporting_time option").slice(1).each(function () {
      if( subscription_plan_txt === $(this).attr("data-subscription") && $(this).attr("data-connectivity").includes(connectivity) ) {
        $(this).show().prop("disabled", false);
      } else {
        $(this).hide().prop("disabled", true);
      }
    })
  }
}

function progress_bar(progress_line_object, direction) {
  var number_of_steps = $('.f1-steps > div').not('[style*="display: none"]').length - 1;
  var now_value = $('.f1-steps > div.f1-step.activated, .f1-steps > div.f1-step.active').length;

  var percentage = ((now_value - 1) / (number_of_steps - 1)) * 100;

  $('.f1-progress-line').css('width', percentage + '%');
}

function validateActiveForm(current_class) {
  // Validate customer fields for step 01
  var hasError = true;
  //Trigger all input and select tags manually

  if(current_class == "step_01"){
    $("." + current_class).find("input, select").each(function () {
      $(this).trigger("blur");
    });
    //Return false if any child div has a class of "has-error"
    $('.step_01').find('div').each(function() {
      if ($(this).hasClass('has-error')) {
        hasError = false;
      }
    })
  } else if(current_class == "step_02") {
    // Validate customer fields for step 01
    var sid_val = $("#customer_ultralink_sid").val();
    if(sid_val === ''){
      if (!($('.customer_ultralink_sid').hasClass('has-error'))) {
        $('.customer_ultralink_sid').addClass(" has-error");
        $('#customer_ultralink_sid').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
      }
      hasError = false;
    } else {
      if(sid_val.length < 12){
        if (!($('.customer_ultralink_sid').hasClass('has-error'))) {
          $('.customer_ultralink_sid').addClass(" has-error");
          $('#customer_ultralink_sid').after("<span class='help-block'>" + I18n.t('sid_is', {sid: I18n.t("invalid")}) + "</span>" );
        }
        hasError = false;
      } else {
        $('#customer_ultralink_sid').parent().find('.help-block').remove();
        $('#customer_ultralink_sid').parent().removeClass("has-error");
        hasError = true;
      }
    }

    if($("#customer_ultralink_subscription_plan").val() == "") {
      hasError = false
      if (!($('.customer_ultralink_subscription_plan').hasClass('has-error'))) {
        $('.customer_ultralink_subscription_plan').addClass(" has-error");
        $('.plan_helper_txt').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
      }
    } else {
      $('.plan_helper_txt').parent().find('.help-block').remove();
      $('#customer_ultralink_subscription_plan').parent().removeClass("has-error");
      hasError = true
    }

    if (hasError) clearWizardReportFieldErrors();
  } else if(current_class == "step_03") {
    var site_type      = $('#site_type').val();
    var sg_value       = $("#customer_ultralink_subscription_plan").val();
    var selfMon_check  = $("#user_request_agency_id").find("option:selected").attr("data-is-self-monitoring") == "true";

    // VFNN validation
    var vfnnValid      = validateReportingFields("customer_ultralink_report_channel_vfnn", true, site_type, sg_value, selfMon_check, false)

    // Acctno validation checking
    var acctValid      = validateReportingFields("customer_ultralink_report_channel_acctno", true, site_type, sg_value, selfMon_check, true)

    // Format validation check
    var formatValid    = validateReportingFields("customer_ultralink_report_channel_format", true, site_type, sg_value, selfMon_check, false)

    // vfnn,format and acctno combine validity check
    hasError = vfnnValid && formatValid && acctValid;

  } else if(current_class == "step_04") {
    // If user selects site type as panel and subscription plan selected as 'Core'
    // then only cellular checkbox should not be select.
    // Its either Both(cellular and ethernet ) or only ethernet.
    if ($("#site_type").val() == 0 && $("#customer_ultralink_subscription_plan").val() == "Core"){
      $('div[class*="oh_reporting"]').show();
      if (!$('#ethernet').is(':checked') && !$('#cellular').is(':checked')){
        $('.connectivity').addClass('has-error');
        $('.step_04 .connectivity_errormsg').text(I18n.t('connectivity') + " " + I18n.t('not_blank'));
        hasError = false;
      } else if (!$('#ethernet').is(':checked') && $('#cellular').is(':checked')) {
        $('.connectivity').addClass('has-error');
        $('.step_04 .connectivity_errormsg').text(I18n.t('only_cellular_notselect'));
        hasError = false;
      } else {
        $('.connectivity').removeClass('has-error');
        $('.step_04 .connectivity_errormsg').text("");
        hasError = true;
      }
    } else if ($("#site_type").val() == 0 && !($("#customer_ultralink_subscription_plan").val() == "Core")) {

      $('div[class*="oh_reporting"]').hide();
      // Check if at least one checkbox with name "my-checkbox" is checked
      if ($('input[name="user_request[connectivity]"]:checked').length > 0) {
        $('.connectivity').removeClass('has-error');
        $('.step_04 .connectivity_errormsg').text("");
        connectivity = true;
      } else {
        $('.connectivity').addClass('has-error');
        $('.step_04 .connectivity_errormsg').text(I18n.t('connectivity') + " " + I18n.t('not_blank'));
        connectivity = false;
      }

      if ($("#customer_ultralink_report_channel_fault_reporting_time").val() === '' || $("#customer_ultralink_report_channel_fault_reporting_time").val() == null) {
        if (!($('.customer_ultralink_report_channel_fault_reporting_time').hasClass('has-error'))) {
          $('.customer_ultralink_report_channel_fault_reporting_time').addClass(" has-error");
          $('#customer_ultralink_report_channel_fault_reporting_time').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
          $('#customer_ultralink_report_channel_fault_reporting_time').prop('selectedIndex', 0);
        }
        fault_time = false;
      } else {
        $('#customer_ultralink_report_channel_fault_reporting_time').parent().find('.help-block').remove();
        $('#customer_ultralink_report_channel_fault_reporting_time').parent().removeClass("has-error");
        fault_time = true;
      }

      if (connectivity && fault_time ){
        if ($('.filter_service_grades').is(':visible')){
          if($('#filter_service_grades').val() === ''){
            if (!($('.filter_service_grades').hasClass('has-error'))) {
              $('.filter_service_grades').addClass(" has-error");
              $('#filter_service_grades').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
              return hasError = false;
            } else {
              return hasError = false;
            }
          }
          else
          {
            $('#filter_service_grades').parent().find('.help-block').remove();
            $('#filter_service_grades').parent().removeClass("has-error");
            return hasError = true
          }
        } else {
           return hasError = true
        }
      } else {
        hasError = false
      }
    }
  }

  return hasError
}

const user_request_new_init = function() {
  if($("#can_access_wizard").val() == 'true'){
    special_vfnn_array = [];
    //Make wizard_sid to be blank to avoid multiple ajax calls for no change on SID input.
    $("#wizard_sid").val('');
    add_next_previous_btns();
    get_device_type_customer();
    prepare_sg_feature_list("new");
    var numberOfSteps = $('.f1-steps .f1-step').length;

    // Based on size of steps reducing the width of each step navigation div
    // Needs to move with CSS
    if (numberOfSteps > 4){
      $('.f1-step').css({
          'position': 'relative',
          'float': 'left',
          'width': '20%' ,
          'padding': '0 5px'
      });
    } else {
      $('.f1-step').css({
          'position': 'relative',
          'float': 'left',
          'width': '25%',
          'padding': '0 5px'
      });
    }
    $("#new-form-next-user-request").show();
    $("#new-form-previous-user-request").hide();
    $("#new-form-submit-user-request").hide();
    // On Submit validation for VFNN and format for non panel device types
    $(document).on("shown.bs.modal", ".modal", function () {
      $("#new-form-submit-user-request").off("click").on("click", function (e) {
        var site_type      = $('#site_type').val();
        var sg_value       = $("#customer_ultralink_subscription_plan").val();
        var selfMon_check  = $("#user_request_agency_id").find("option:selected").attr("data-is-self-monitoring") == "true";

        // VFNN validation check
        var vfnnValid      = validateReportingFields("customer_ultralink_report_channel_vfnn", true, site_type, sg_value, selfMon_check, false)

        // Acctno validation checking
        var acctValid      = validateReportingFields("customer_ultralink_report_channel_acctno", true, site_type, sg_value, selfMon_check, true)

        // Format validation check
        var formatValid    = validateReportingFields("customer_ultralink_report_channel_format", true, site_type, sg_value, selfMon_check, false)

        if(vfnnValid && formatValid && acctValid){
          $("#new_user_request").submit();
        } else {
          return false;
        }
      })
    })

    initToolTip();
    restrict_addons_for_core("user_request");
    // Hide site type filter if No self mon linked to dealer.
    // validate_self_mon_exists($("#modal-body-new-user-request #user_request_agency_id option"));

    const user_request_model = "user-request";
    //TODO: Generic feature_list implementation for user request request/report channel & sites
    filter_vfnn_by_agency_id(user_request_model, $("#modal-body-new-user-request #user_request_agency_id").val());
    country_select_init("customer", "#modal-body-new-user-request #customer_country_code", "#modal-body-new-user-request #customer_state");
    $("#customer_country_code").trigger('change');
    $("#customer_country_code").on('change', function() {});
      //update_timezones("customer_ultralink", "#customer_country_code")
      //update_service_grades("customer_ultralink_report_channel", "#customer_country_code")
    const default_branch = $("#modal-body-new-user-request #user_request_agency_id option:selected").attr("data-branch-id");
    $("#modal-body-new-user-request #customer_ultralink_report_channel_branch_id").val(default_branch);
    $("#modal-body-new-user-request #user_request_agency_id").off("change").on('change', function() {
      this.branch_select = $("#modal-body-new-user-request #customer_ultralink_report_channel_branch_id");
      this.branch_options = $("#modal-body-new-user-request #customer_ultralink_report_channel_branch_id option");
      const agency_id =  $("#user_request_agency_id").val();
      const is_self_monitoring = $("#modal-body-new-user-request #user_request_agency_id option:selected").attr("data-is-self-monitoring");

      filter_vfnn_by_agency_id(user_request_model, $("#user_request_agency_id").val());

      var site_type = $("#site_type").val();

      // filter service grades based on agency selection
      filter_service_grade_by_smonitoring("new", user_request_model, is_self_monitoring, site_type, "customer_ultralink_report_channel");

      const branch_id = $("#modal-body-new-user-request #user_request_agency_id option:selected").attr("data-branch-id");
      $("#modal-body-new-user-request #customer_ultralink_report_channel_branch_id").val(branch_id);
      let selected_branch = "";
      if (this.branch_select.is(":visible")) {
        this.branch_options.each(function() {
          const branch_option = $(this);
          if (branch_option.attr("data-agency-id") === agency_id) {
            if ($(this).prop("selected")) {
              selected_branch = $(this).val();
            }
            return branch_option.prop("selected", false).show().prop("disabled", false);
          } else {
            return branch_option.prop("selected", false).hide().attr("disabled", true);
          }
        });
        this.branch_select.val(selected_branch).trigger('change');
        this.branch_select.trigger('change');
      }
      if (agency_id === "") {
        return this.branch_select.attr("readonly", true);
      } else {
        return this.branch_select.attr("readonly", false);
      }
    });
    $("#modal-body-new-user-request #user_request_agency_id option[value=\"\"]").attr("selected", true).trigger('change');
  } else {
    $("#new-form-next-user-request").hide();
    $("#new-form-previous-user-request").hide();
    $("#new-form-submit-user-request").show();
    $('#customer_ultralink_sid').on('keyup blur', function() {
      var usid = $('#customer_ultralink_sid').val();
      if (usid !== "" && usid.length == 12) {
        // update_servicegrade(usid, "#customer_ultralink_report_channel_service_grade",
        //                 "#customer_ultralink_report_channel_branch_id");
          $.ajax({
           url: "/ultralinks/get_device_details",
            type: "GET",
            data: { sid: usid },
            success(data, status, xhr) {
              if (!sessionExpired(xhr)) {
                $('#site_type').val(data["device_type"]);
                if (data["device_type"] == 1){
                  $('#is_recorder').val(true);
                } else {
                  $('#is_recorder').val(false);
                }
                show_device_type_label(data["device_type"],"customer_ultralink_sid");
                $("#customer_ultralink_report_channel_service_grade").trigger('change');
                $("#customer_ultralink_report_channel_service_grade").prop('selectedIndex',0);
                $("#customer_ultralink_report_channel_service_grade option:not(:first-child)").remove();
                for (i = 0; i <= data["service_grades"].length - 1; i++) {
                  var option_att = data["service_grades"][i][2]
                  var option_val = data['service_grades'][i][0]
                  $('<option>').attr({
                    'data-is-lower-grade': option_att['data-is-lower-grade'],
                    'data-is-recorder': option_att['data-is-recorder'],
                    'data-is-camera': option_att['data-is-camera'],
                    'data-standard-fcode': option_att['data-standard-fcode'],
                    value: option_val
                  }).text(option_val).appendTo('#customer_ultralink_report_channel_service_grade');
                }
                // site_type_filter();
                disable_recorder_sg();

                // Show only selfmon CMS for device type camera
                camera_agency_filter($('#site_type').val(), $("#user_request_agency_id option"))
                if ($('#site_type').val() == 1 || $('#site_type').val() == 2) {
                  $("#customer_ultralink_report_channel_service_grade").prop('selectedIndex',0);
                  recorder_input_fields("system");
                } else {
                  $("#customer_ultralink_report_channel_service_grade").prop('selectedIndex',0);
                  if($(".sg_features_block").length && $(".sg_features_block").is(':visible')) {
                    $(".sg_features_block").hide();
                  }
                  sg_filter_type($('#site_type').val());
                  panel_input_fields();
                }

                // Enabling or Disabling the service grades according to the Site Type and Agency
                prepare_service_grades();
              }
            }
          });
        }
    });

    initToolTip();
    restrict_addons_for_core("user_request");
    oh_reporting_input_fields("customer_ultralink_report_channel", "user_request");
    // Hide site type filter if No self mon linked to dealer.
    // validate_self_mon_exists($("#modal-body-new-user-request #user_request_agency_id option"));


    const user_request_model = "user-request";
    //TODO: Generic feature_list implementation for user request request/report channel & sites
    create_sg_feature_list("#customer_ultralink_report_channel_service_grade", "#site_type");
    filter_vfnn_by_agency_id(user_request_model, $("#modal-body-new-user-request #user_request_agency_id").val());
    country_select_init("customer", "#modal-body-new-user-request #customer_country_code", "#modal-body-new-user-request #customer_state");
    $("#customer_country_code").trigger('change');
    $("#customer_country_code").on('change', function() {});
      //update_timezones("customer_ultralink", "#customer_country_code")
      //update_service_grades("customer_ultralink_report_channel", "#customer_country_code")
    const default_branch = $("#modal-body-new-user-request #user_request_agency_id option:selected").attr("data-branch-id");
    $("#modal-body-new-user-request #customer_ultralink_report_channel_branch_id").val(default_branch);
    $("#modal-body-new-user-request #user_request_agency_id").off("change").on('change', function() {
      this.branch_select = $("#modal-body-new-user-request #customer_ultralink_report_channel_branch_id");
      this.branch_options = $("#modal-body-new-user-request #customer_ultralink_report_channel_branch_id option");
      const agency_id =  $("#user_request_agency_id").val();
      const is_self_monitoring = $("#modal-body-new-user-request #user_request_agency_id option:selected").attr("data-is-self-monitoring");

      filter_vfnn_by_agency_id(user_request_model, $("#user_request_agency_id").val());

      var site_type = $("#site_type").val();

      // filter service grades based on agency selection
      filter_service_grade_by_smonitoring("new", user_request_model, is_self_monitoring, site_type, "customer_ultralink_report_channel");

      const branch_id = $("#modal-body-new-user-request #user_request_agency_id option:selected").attr("data-branch-id");
      $("#modal-body-new-user-request #customer_ultralink_report_channel_branch_id").val(branch_id);
      let selected_branch = "";
      if (this.branch_select.is(":visible")) {
        this.branch_options.each(function() {
          const branch_option = $(this);
          if (branch_option.attr("data-agency-id") === agency_id) {
            if ($(this).prop("selected")) {
              selected_branch = $(this).val();
            }
            return branch_option.prop("selected", false).show().prop("disabled", false);
          } else {
            return branch_option.prop("selected", false).hide().attr("disabled", true);
          }
        });
        this.branch_select.val(selected_branch).trigger('change');
        this.branch_select.trigger('change');
      }
      if (agency_id === "") {
        return this.branch_select.attr("readonly", true);
      } else {
        return this.branch_select.attr("readonly", false);
      }
    });

    // Validate account number for non-EU dealer user request from dashboard
    initAcctnoValidation("new_user_request", "customer_ultralink_report_channel_acctno", "#new-form-submit-user-request");

    $("#modal-body-new-user-request #user_request_agency_id option[value=\"\"]").attr("selected", true).trigger('change');
    return filter_format_by_vfnn(user_request_model);
  }
};

update_feature_list = function(mode, fcode) {
  var _parent, i, j, ref, results;
  _parent = "#modal-body-" + mode + "-user-request";
  results = [];
  for (i = j = 0, ref = fcode.length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
    results.push($(_parent + " #sg_feature_" + i).prop('checked', get_checked_status(fcode[i])));
  }
  return results;
};

get_checked_status = function(bit) {
  if (bit === "1") {
    return true;
  } else {
    return false;
  }
};

prepare_sg_feature_list = function(mode) {
  this.modal = "#modal-body-" + mode + "-user-request";
  this.new_site_svc_grade_select = $(this.modal + " #customer_ultralink_report_channel_service_grade");
  this.new_site_svc_grade_select_val = $(this.modal + " #customer_ultralink_report_channel_service_grade").val();

  if ($("#user_request_request_type").val() === "1") {
    if (this.new_site_svc_grade_select.val() === "") {
      $(this.modal + " .sg_features_block").hide();
    } else {
      $(this.modal + " .sg_features_block").show();
    }
    this.new_site_svc_grade_select.off("change").on('change', (function(_this) {
      return function() {
        var fcode;
        if (_this.new_site_svc_grade_select.val() !== "") {
          $(_this.modal + " .sg_features_block").show();
          fcode = $(_this.modal + " #customer_ultralink_report_channel_service_grade option:selected").attr("data-standard-fcode");
          if ((_this.new_site_svc_grade_select_val === _this.new_site_svc_grade_select.val()) && mode === "edit") {
            fcode = $(_this.modal + " #report_channel_feature_code").val();
          }
          return update_feature_list(mode, fcode);
        } else {
          return $(_this.modal + " .sg_features_block").hide();
        }
      };
    })(this));
    return $(this.modal + " .reset_features").off('click').on('click', function() {
      var fcode;
      fcode = $("#customer_ultralink_report_channel_service_grade option:selected").attr("data-standard-fcode");
      if (fcode === undefined){
        fcode = $("#fcode").val();
      }
      return update_feature_list(mode, fcode);
    });
  }
};

get_device_type_customer = function() {
  $("#device_type_label").hide();
  process_sid_input();
}

var enable_refresh = function() {
  if ($("#message-area a.close").attr("data-refresh") !== "enabled") {
    $("#message-area a.close").attr("data-refresh", "enabled");
    return $("#message-area a.close").on('click', () => setTimeout(refresh, 30000));
  }
};

const advfind_country_select_init = function() {
  // custom event triggered once advfind has loaded
  $('#modal-body-advfind-customer').on('advsearch_loaded', function() {
    country_select_init("customer_query", "#customer_query_country_code");
    return $("#customer_query_country_code").trigger('change');
  });

  return $('#searchbar').on('advsearch_loaded', function() {
    country_select_init("customer_query", "#customer_query_country_code");
    return $("#customer_query_country_code").trigger('change');
  });
};

const init_page_length = function() {
  // String.prototype.includes is not support in (Internet Explorer and Opera)
  // String.prototype.indexOf indexOf returns the index of the first character
  // of the substring if it is in the string, otherwise it returns -1

  let searchArr;
  const has_length = window.location.search.indexOf("length")  > -1;
  //set selected in page_length
  if (window.location.search.indexOf("length") > -1) {
    searchArr = window.location.search.split("&");
    searchArr.some(function(elem) {
      if (elem.indexOf("length") > -1) {
        return $('#page_length').val(elem.split("=")[1]);
      }
    });
  }

  return $('#page_length').off('change').on('change', function(e) {
    let op;
    const $page_len = $(this).val();
    let search = "";
    if ((window.location.search.indexOf("length") > -1) && !((window.location.search.indexOf("page") > -1))) {
      searchArr = window.location.search.split("&");
      searchArr = searchArr.map(function(elem) {
        if (elem.indexOf("length") > -1) { return elem.replace(/\d+/, $page_len); }
      });
      search = searchArr.join("&");
    } else if ((window.location.search.indexOf("page") > -1) && (window.location.search.indexOf("length") > -1)) {
      search = replace_search_val(window.location.search, $page_len).join("&");
    } else if ((window.location.search.indexOf("page") > -1) && !((window.location.search.indexOf("length") > -1))) {
      op = window.location.search.length > 1 ? "&" : "?";
      search = window.location.search + op + "length=" + $page_len;
      search = replace_search_val(search, $page_len).join("&");
    } else {
      op = window.location.search.length > 1 ? "&" : "?";
      search = window.location.search + op + "length=" + $page_len;
    }
    return window.location.search = search;
  });
};

var replace_search_val = function(search_val, page_len) {
  const search_res = [];
  search_val.split("&").map(function(elem) {
    if (elem.indexOf("page") > -1) {
      search_res.push(elem.replace(/\d+/, 1));
    }
    else if(elem.indexOf("length") > -1) {
      search_res.push(elem.replace(/\d+/, page_len));
    }
    else {
      search_res.push(elem)
    }
  });
  return search_res;
};

const document_ready = function() {
  //$('.pagination a').on 'click', ->
  //  scrollup()
  //  return
  //if ($("#created").length)
  //  setTimeout(refresh, 30000)

  init_object_crud({
    'customer': {
      new: customer_new_init,
      delete: customer_delete_init
    },
    'user-request': {
      new: user_request_new_init
    }
  });
  init_page_length();
  init_search();

  if ($("#customers-table").length > 0) {
    return $.ajax({
      url:  '/customers/cust_export_fail_alert',
      type: 'GET',
      "success"(data, status, xhr) {
        if (!sessionExpired(xhr)) {
          if (data["result"] !== "") {
            return show_error(data["result"]);
          }
        }
      }
    });
  }
};

var init_search = () => $('.searchtoggle.customers').on('on_search_toggled', function() {
  if (!page_tag_exists('customers_edit')) { init_advsearch('/customers/advfind'); }
  create_advfind_buttons('customer');
  advfind_country_select_init();
});

toggle_company_placeholder_message = function(label, autocomplete_field_id) {
  label.text(I18n.t('agency') + '/' + I18n.t('branch'));
  if (autocomplete_field_id.val() === "") {
    label.text("");
    return autocomplete_field_id.attr("placeholder", I18n.t('autocomplete_branch_cms_company_message'));
  }
};

company_selection_callback = function(data, model) {
    var site_type = $("#site_type").val();
    const agency_id     = data.item["data-agency-id"]
    const is_self_monitoring = data.item["data-is-self-monitoring"]
    $("#customer_ultralink_report_channel_agency_id").val(agency_id);
    $("#customer_ultralink_report_channel_branch_id").val(data.item["branch_id"]);
    $("#is_self_monitoring").val(is_self_monitoring);
    $("#customer_ultralink_report_channel_service_grade").trigger("change");
    filter_vfnn_by_agency_id(model, agency_id);
    filter_service_grade_by_smonitoring("new", model, is_self_monitoring, site_type, "customer_ultralink_report_channel");
};

jQuery(function() {
  run_ontag('customers_edit', () => customer_edit_init());
  return run_ontag('customers', function() {
    document_ready();
    if ($("#created").length) {
      setTimeout(refresh, 30000);
    }
    $(document).on('pjax:end', document_ready);
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = 0;
      $.pjax.defaults.timeout = false;
      return $(document).pjax('.pagination a', '[data-pjax-container]');
    }
  });
});
