/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
var root = window //typeof exports !== "undefined" && exports !== null ? exports : this;
root.alarm_receiver_interface_init = function (sender, action) {
  var p = "#modal-body-".concat(action, "-alarm-receiver-interface");
  var rp = $('input[name="alarm_receiver_interface[automation_option]"]', p);
  var checked = function checked() {
    return rp.filter(":checked");
  };
  var toggle = function toggle() {
    $(".automation_option", p).hide();
    if (checked().val() === "1") {
      return $(".serial_option", p).show();
    } else {
      return $(".ip_option").show();
    }
  };
  // hide the SSL/TLS for Fibro protocol
  $("#alarm_receiver_interface_protocol").off("change").on('change', function() {
   if ($("#alarm_receiver_interface_protocol").val() == "fibro") {
      $(".alarm_receiver_interface_fwkey").hide();
    } else {
      $(".alarm_receiver_interface_fwkey").show();
    }
  });
  if (checked().length === 0) {
    $(rp[0]).attr("checked", true);
  }
  var automation_ops = $(
    'input[name="alarm_receiver_interface[automation_option]"]',
    p
  );
  if (automation_ops.filter(":checked").length === 0) {
    automation_ops.first().attr("checked", true);
  }
  toggle();
  return rp.off("click").on("click", toggle);
};

var client_certificate_new_init = function client_certificate_new_init() {
  return country_select_init(
    "client_certificate",
    "#client_certificate_country_code"
  );
};

// CMS Test message call functionality
var cms_test_message_init = function() {
  // Initialize multiselect for Event type dropdown
  var defaultSelectOptions = {
    maxHeight: 200,
    numberDisplayed: 3,
    includeSelectAllOption: true,
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true
  };
  $("#cms_test_message_event_type").multiselect(defaultSelectOptions);

  // Disable event type dropdown initially
  $("#cms_test_message_event_type").multiselect("disable");

  // Form submission handling
  $('#new_cms_test_message').submit(function(event) {
    var dropdownValue = $('#cms_test_message_event_type').val();
    var deviceType = $("#cms_test_message_device_type").val();

    // Validate event type dropdown value if device type is recorder (siteType === "1")
    if (deviceType === "1" && dropdownValue.length === 0) {
      event_type_show_error();
      event.preventDefault(); // Prevent form submission
    }
  });

  // Enabling the event type dropdown based on the device type change or format change
  $("#cms_test_message_format, #cms_test_message_device_type").off("change").on('change', function() {
    var siteType = $("#cms_test_message_device_type").val();
    var device_format = $("#cms_test_message_format").val();

    if (siteType && device_format && siteType === "1") {
        // Enable event type dropdown
        $("#cms_test_message_event_type").multiselect("enable");

        // Ajax call for to fetch the event types based on device type
        $.ajax({
          url: "/alarm_receiver_interfaces/get_event_types",
          type: "GET",
          data: { device_type: siteType, device_format: device_format },
          success: function(data) {
            $("#cms_test_message_event_type").multiselect('dataprovider', data["event_types"]);
          },
          error: function(xhr, status, error) {
            console.log("Error fetching event types:", error);
            $("#cms_test_message_event_type").multiselect('dataprovider', []);
          }
        });
    } else {
      $('#cms_test_message_event_type').multiselect('dataprovider', []);
      $("#cms_test_message_event_type").multiselect("disable");
      event_type_remove_error();
    }
  });

  // Hide the error message when options are selected in the multiselect dropdown
  $('#cms_test_message_event_type').change(function() {
    if ($(this).val().length > 0) {
      event_type_remove_error();
    } else {
      event_type_show_error();
    }
  });
};

// Show error for event type dropdown
function event_type_show_error() {
  if (!$('.cms_test_message_event_type').hasClass('has-error')) {
    $('.cms_test_message_event_type').addClass('has-error');
    $('#cms_test_message_event_type').parent().after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>");
  }
}

// Remove error for event type dropdown
function event_type_remove_error() {
  $('.cms_test_message_event_type').removeClass('has-error');
  $('.cms_test_message_event_type').find('.help-block').remove();
}

var document_ready = function document_ready() {
  var crud_options = {
    "alarm-receiver-interface": {
      new: alarm_receiver_interface_init,
      edit: alarm_receiver_interface_init,
    },
    "client-certificate": {
      new: client_certificate_new_init,
    },
    "cms-test-message": {
      new: cms_test_message_init,
    },
  };
  init_datatable(crud_options);
  return init_search();
};

var init_datatable = function init_datatable(crud_options) {
  options = {
    fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
      return (nRow.style.color = aData[aData.length - 1]);
    },
  };
  return dt.initDatatable(
    $("#alarm-receiver-interfaces-table"),
    options,
    crud_options
  );
};

var init_search = function init_search() {
  return $(".searchtoggle.alarm_receiver_interfaces").on(
    "on_search_toggled",
    function () {
      init_advsearch("/alarm_receiver_interfaces/advfind");
      create_advfind_buttons("alarm_receiver_interface");
    }
  );
};

jQuery(function () {
  return run_ontag("alarm_receiver_interfaces", function () {
    document_ready();
    $(document).on("pjax:end", document_ready);
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      return $(document).pjax(".pagination a", "[data-pjax-container]");
    }
  });
});
