/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready,
  filter_format_by_vfnn,
  filter_vfnn_by_agency_id,
  init_datatable,
  init_modal,
  init_search,
  unassociated_ultralink_edit_init,
  indexOf =
    [].indexOf ||
    function (item) {
      for (var i = 0, l = this.length; i < l; i++) {
        if (i in this && this[i] === item) return i;
      }
      return -1;
    };

document_ready = function () {
  var crud_options;
  crud_options = {
    "unassociated-ultralink": {
      edit: unassociated_ultralink_edit_init,
    },
  };
  init_datatable(crud_options);
  return init_search();
};

init_modal = function () {
  var modal;
  modal = "#modal-body-edit-unassociated-ultralink";
  this.branch_select = $(
    modal + " #customer_ultralink_report_channel_branch_id"
  );
  this.branch_options = $(
    modal + " #customer_ultralink_report_channel_branch_id option"
  );
  this.vfnn_select = $(modal + " #customer_ultralink_report_channel_vfnn");
  this.vfnn_options = $(
    modal + " #customer_ultralink_report_channel_vfnn option"
  );
  this.format_select = $(modal + " #customer_ultralink_report_channel_format");
  this.format_options = $(
    modal + " #customer_ultralink_report_channel_format option"
  );
  this.preferred_format = $(
    modal +
      " #customer_ultralink_report_channel_preferred_report_channel_format"
  );
  this.notification_group = $(modal + " #notification_group_container");
  this.allow_notification = $(
    modal + " #customer_ultralink_report_channel_notification_group_enabled"
  );
  this.master_unit = $(
    modal + " .customer_ultralink_report_channel_master_unit"
  );
  this.master_unit_checkbox = $(
    modal + " #customer_ultralink_report_channel_master_unit"
  );
  this.form = $(modal + " form");
  this.special_vfnn = this.form.data("special-vfnn");
  this.special_vfnn_array = [];
  if (this.special_vfnn) {
    return (this.special_vfnn_array = ("" + this.special_vfnn).split(","));
  }
};

filter_vfnn_by_agency_id = function () {
  this.branch_select.off("change").on(
    "change",
    (function (_this) {
      return function () {
        var agency_id, selected_vfnn, is_self_monitoring, site_type;
        agency_id = _this.branch_select
          .find("option:selected")
          .attr("data-agency-id");
        is_self_monitoring  = _this.branch_select.find("option:selected").attr("data-is-self-monitoring");
        selected_vfnn = "";
        _this.vfnn_options.each(function () {
          var vfnn_option;
          vfnn_option = $(this);
          if (vfnn_option.attr("data-agency-id") === agency_id) {
            if (vfnn_option.prop("selected")) {
              selected_vfnn = vfnn_option.val();
            }
            return vfnn_option.show().prop("disabled", false);
          } else if (vfnn_option.val() !== "") {
            return vfnn_option
              .prop("selected", false)
              .hide()
              .attr("disabled", true);
          }
        });
        if (agency_id) {
          _this.vfnn_select.attr("readonly", false);
        } else {
          _this.vfnn_select.attr("readonly", true);
        }
        _this.vfnn_select.val(selected_vfnn).trigger("change");
        // site_type = "0" is for panel, Recorder feature not required here
        filter_service_grade_by_smonitoring("edit", "unassociated-ultralink", is_self_monitoring, $("#site_type").val(), "customer_ultralink_report_channel")
      };
    })(this)
  );
  return this.branch_select.trigger("change");
};

filter_format_by_vfnn = function () {
  var recorder_formats = this.form.data('recorder-formats');
  var camera_formats   = this.form.data('camera-formats');
  this.vfnn_select.off("change").on(
    "change",
    (function (_this) {
      return function () {
        var has_formats, master_unit_val, option, selected_vfnn, vfnn_protocol;
        vfnn_protocol = _this.vfnn_select
          .find("option:selected")
          .attr("data-protocol");
        has_formats = false;
        master_unit_val = vfnn_select
          .find("option:selected")
          .attr("data-master-unit");
        selected_vfnn = vfnn_select.find("option:selected").val();
        _this.format_options.slice(1).each(function () {
          var format_option, option_protocols;
          format_option = $(this);
          option_protocols = format_option.attr("data-protocols");
          if (
            option_protocols !== void 0 &&
            option_protocols.indexOf(vfnn_protocol) >= 0
          ) {
            format_option.show()
              .prop("disabled", false);
            return (has_formats = true);
          } else {
            return format_option.hide()
              .attr("disabled", true);
          }
        });
        if (vfnn_protocol) {
          _this.format_select.attr("readonly", false);
          _this.allow_notification.prop("checked", false);
          if (indexOf.call(_this.special_vfnn_array, selected_vfnn) >= 0) {
            notification_group.show();
            if (master_unit_val) {
              master_unit.hide();
              master_unit_checkbox.prop("checked", false);
            } else {
              master_unit.show();
              master_unit_checkbox.prop("checked", false);
            }
          } else {
            notification_group.hide();
            allow_notification.prop("checked", false);
            master_unit_checkbox.prop("checked", false);
          }

          device_type_formats($("#site_type").val(), "customer_ultralink_report_channel_format",
                                  recorder_formats, camera_formats )
          if (has_formats) {
            option = _this.format_select.find(
              "option[value='" + preferred_format.val() + "']:not([disabled])"
            );
            if (!(option.length > 0)) {
              option = format_select.find("option:not([disabled])");
            }
          } else {
            _this.format_select
              .find("option")
              .first()
              .show()
              .prop("disabled", false)
              .prop("selected", true);
          }
          return _this.format_select.trigger("change");
        } else {
          notification_group.hide();
          allow_notification.prop("checked", false);
          master_unit_checkbox.prop("checked", false);
          hide_select_options("#customer_ultralink_report_channel_format");
        }

      };
    })(this)
  );
  return this.vfnn_select.trigger("change");
};

unassociated_ultralink_edit_init = function () {
  init_modal();
  var site_type = $("#site_type").val();
  $("#customer_ultralink_sid").attr("readonly", true);
  country_select_init("customer", "#customer_country_code");
  filter_vfnn_by_agency_id();

  //Show only selfmons for camera as device type
  camera_agency_filter($('#site_type').val(), $("#customer_ultralink_report_channel_branch_id option"))

  $("#modal-body-edit-unassociated-ultralink").on("change", "#customer_site_type", function(){
    if ($(this).val() === "1") {
      $("#customer_ultralink_report_channel_branch_id").prop('selectedIndex',0);
      $("#customer_ultralink_report_channel_service_grade").prop('selectedIndex',0);
      branch_details = $("#customer_ultralink_report_channel_branch_id option");
      branch_details.each(function () {
        if( $(this).attr("data-is-self-monitoring") == "true" ) {
          $(this).show().prop("disabled", false);
        } else {
          $(this).hide().prop("disabled", true);
        }
      });
      recorder_input_fields("system");
      sg_filter_type($(this).val());
    } else {
      $("#customer_ultralink_report_channel_branch_id").prop('selectedIndex',0);
      $("#customer_ultralink_report_channel_service_grade").prop('selectedIndex',0);
      branch_details = $("#customer_ultralink_report_channel_branch_id option");
      branch_details.each(function () {
        if( $(this).attr("data-is-self-monitoring") == "false" ) {
          $(this).show().prop("disabled", false);
        }
      });
      if($(".sg_features_block").length && $(".sg_features_block").is(':visible')) {
        $(".sg_features_block").hide();
      }
      sg_filter_type($(this).val());
      panel_input_fields();
    }
  });

  // Loading service grades based on the device type
  sg_filter_type(site_type === "1" || site_type === "2" ? site_type : "0");

  // Disabling the vfnn,acctno and format for recorder with selmon and core sg
  if (site_type === "1") {
    $("#customer_ultralink_report_channel_service_grade").off("change").on('change', function() {
      const selectedOption = $(this).find(':selected');
      const isLowerGrade = selectedOption.attr("data-is-lower-grade") === "true";
      const isRecorder = selectedOption.attr("data-is-recorder") === "true";

      if (isLowerGrade && isRecorder) {
        toggleFields(false);
      } else {
        toggleFields(true);
      }
    });
  }

  // Validate account number
  initAcctnoValidation("edit_unassociated_ultralink_", "customer_ultralink_report_channel_acctno", "#edit-form-submit-unassociated-ultralink");

  return filter_format_by_vfnn();
};

// Function to toggle visibility based on conditions
var toggleFields = function(show) {
  const fields = [
    ".customer_ultralink_report_channel_vfnn",
    ".customer_ultralink_report_channel_acctno",
    ".customer_ultralink_report_channel_format"
  ];
  fields.forEach(field => $(field).toggle(show));
}

var init_datatable = (crud_options) =>
  dt.initDatatable($("#unassociated-ultralinks-table"), {}, crud_options);

init_search = function () {
  return $(".searchtoggle.unassociated_ultralinks").on(
    "on_search_toggled",
    function () {
      init_advsearch("/unassociated_ultralinks/advfind");
      create_advfind_buttons("unassociated_ultralink");
    }
  );
};

jQuery(function () {
  return run_ontag("unassociated_ultralinks", function () {
    return document_ready();
  });
});
