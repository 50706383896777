/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const root = window //typeof exports !== 'undefined' && exports !== null ? exports : this;

const init_toggle_state = function() {
  const toggle_menu = function() {
    try {
      let imgScr = default_logo;
      let sm_logo = false
      if ($(window).width() <= 881) {
        if (typeof default_logo_sm !== 'undefined') { imgScr = default_logo_sm;  sm_logo = true; }
      }
      if (imgScr !=='') {
        $(".site_title img:last-child").remove()
        $('.site_title').append(imgScr);
        if (sm_logo) { $(".site_title img:last-child").css({ "height": "46px", "padding-left": "9px", "width": "80%", "margin-top": "0px"}); }
        return
      }
    } catch (error) {}
  };

  toggle_menu();

  $(window).on('resize', () => toggle_menu());

  // Remember the state of the stats
  this.persist = false;
  const Cookies = require('./js_cookie');
  if (!Cookies.get("statsShowNav")) { Cookies.set("statsShowNav", true); }
  $("#site_stats .collapse-link").on('click', function() {
    if (!this.persist) { return Cookies.set("statsShowNav", !(Cookies.get("statsShowNav")==='true')); }
  });
  if (Cookies.get("statsShowNav") === 'false') {
    return $('#site_stats .x_content').hide('slow');
  }
};

const init_search = function() {
  const $searchtoggle = $('.searchtoggle');
  const $searchbar = $('#searchbar');
  const $search = $("#search");
  const toggle = function(focus) {
    if (focus == null) { focus = false; }
    return $searchbar.slideToggle(300, function() {
      if (focus) { $search.trigger('focus'); }
    });
  };

  return $searchtoggle.off('click').on('click', function(e) {
    if ($('#advsearch_content').children().length === 0) {
      $(this).trigger('on_search_toggled');
      setTimeout(() => toggle(true)
      , 500);
    } else {
      toggle(true);
    }
  });
};

  // apply this if title needs to be within the navbar
  // window.onresize = (e) ->
  //   if window.innerWidth > 730
  //     $search.removeClass('search-sm').addClass('search-md')
  //   else
  //     $search.removeClass('search-md').addClass('search-sm')

const init_export_status = function() {
  if (($( ".top_nav .nav_menu" ).length > 0) && ($(".export_ongoing").length > 0)) {
    return setTimeout(check_export_progress, 300);
  }
};

var check_export_progress = () => $.get('/get_export_details.json', function(obj, status, xhr) {
  if (sessionExpired(xhr)) { return; }
  const {
    ongoing_exports
  } = obj;
  if (ongoing_exports.length === 0) {
    $(".export_ongoing").html('');
    return;
  }

  if (ongoing_exports.length > 0) {
    const array = ongoing_exports + "";
    $(".export_ongoing").html("<marquee direction='left' scrollamount='4'>" + array + "</marquee>");
    return setTimeout(check_export_progress, 30000);
  }
});

// String.prototype.includes is not support in (Internet Explorer and Opera)
// String.prototype.indexOf. indexOf returns the index of the first character
// of the substring if it is in the string, otherwise it returns -1

const init_activemenu = function() {
  // set active sidebar link for grouped tabs
  const CURRENT_PATH = window.location.pathname.split('#')[0].split('?')[0];
  const $SIDEBAR_MENU = $('#sidebar-menu');
  let CURRENT_URL = '';
  if (CURRENT_PATH.indexOf('/customers/dashboard') > -1) {
      CURRENT_URL = '/customers/dashboard';
  } else if ((CURRENT_PATH.indexOf('/customers/portal_analytics') > -1)) {
    CURRENT_URL = '/customers/dashboard';
  } else if ((CURRENT_PATH.indexOf('/ultralinks') > -1) ||
      (CURRENT_PATH.indexOf('/customers') > -1) ||
      (CURRENT_PATH.indexOf('/customer_exports') > -1) ||
      (CURRENT_PATH === "/")) {
    CURRENT_URL = '/customers';
  } else if (CURRENT_PATH.indexOf('/systems') > -1) {
    CURRENT_URL = '/systems';
  } else if (CURRENT_PATH.indexOf('/vpn_config') > -1) {
    CURRENT_URL = '/vpn_config';
  } else if (CURRENT_PATH.indexOf('/oh2000') > -1) {
    CURRENT_URL = '/oh2000_panels';
  } else if (CURRENT_PATH.indexOf('/users') > -1) {
    CURRENT_URL = '/users';
  } else if (CURRENT_PATH.indexOf('/user_requests') > -1) {
    CURRENT_URL = '/user_requests';
  } else if (CURRENT_PATH.indexOf('/user_access_logs') > -1) {
    CURRENT_URL = '/user_access_logs';
  } else if ((CURRENT_PATH.indexOf('/ultralink_events') > -1) ||
      (CURRENT_PATH.indexOf('/ultralink_event_exports') > -1)) {
    CURRENT_URL = '/ultralink_events';
  } else if (CURRENT_PATH.indexOf('/unassociated_ultralinks') > -1) {
    CURRENT_URL = '/unassociated_ultralinks';
  } else if (CURRENT_PATH.indexOf('/service_grades') > -1) {
    CURRENT_URL = '/service_grades';
  } else if ((CURRENT_PATH.indexOf('/alarm_receiver_interfaces') > -1) ||
      (CURRENT_PATH.indexOf('/vfnns') > -1) ||
      (CURRENT_PATH.indexOf('/distributor_links') > -1) ||
      (CURRENT_PATH.indexOf('/support_contact_users') > -1) || (CURRENT_PATH.indexOf('/companies') > -1)) {
    CURRENT_URL = '/companies';
  } else if (CURRENT_PATH.indexOf('/download_jobs') > -1) {
    CURRENT_URL = '/ultralink_software_versions';
  } else if ((CURRENT_PATH.indexOf('/content_library') > -1) ||
      (CURRENT_PATH.indexOf('admin/content_library') > -1) ||
      (CURRENT_PATH.indexOf('admin/article_assets') > -1)) {
    CURRENT_URL = '/content_library';
  } else if (CURRENT_PATH.indexOf('/reports') > -1) {
    CURRENT_URL = '/reports';
  } else if (CURRENT_PATH.indexOf('/batch_service_requests') > -1) {
    CURRENT_URL = '/batch_service_requests';
  } else if (CURRENT_PATH.indexOf('/ultralink_configs') > -1) {
    CURRENT_URL = '/ultralink_software_versions';
  } else if ((CURRENT_PATH.indexOf('/sim_managements') > -1) ) {
    CURRENT_URL = '/sim_managements?partner=carrier';
  }

  if (CURRENT_URL) {
    return $SIDEBAR_MENU.find('a[href="' + CURRENT_URL + '"]').parent('li').addClass('current-page');
  }
};

root.init_advsearch = function(url, options) {
  if (options == null) { options = {}; }
  if (url) {
    return $('#advsearch_content').load(url, function() {
      if (support_float_label) { initFloatLabels(); }

      const $searchbar = $('#searchbar');
      const $datetimePickerInput = $searchbar.find(".datetimepicker-input");
      let maxDateObj = false;

      if ($datetimePickerInput.attr('data-range-end') !== undefined) {
        const end = $(".datetimepicker-input").attr('data-range-end');
        maxDateObj = new Date(end.replace(/\s+/, 'T'));
      }

      const dateNow = new Date();
      $searchbar.find(".datetimepicker").datetimepicker({
        format:'YYYY-MM-DD HH:mm:ss',
        ignoreReadonly: true,
        maxDate: maxDateObj,
        defaultDate: false,
        useCurrent: false
      });
      const $clearDate = $searchbar.find('.clear-date');
      $clearDate.off('click').on('click', function() {
        $("#" + $(this).attr('for')).val("");
        return $("#" + $(this).attr('for')).next('.bootstrap-datetimepicker-widget').hide();
      });
      $clearDate.trigger('click');

        var defaultSelectOptions = {
          maxHeight: 200,
          maxHeight: 1000,
          numberDisplayed: 3,
          includeSelectAllOption: true,
          enableFiltering: true,
          enableCaseInsensitiveFiltering: true
        };
        $("#ultralink_event_query_categories").multiselect(defaultSelectOptions);
      $searchbar.trigger('advsearch_loaded');
    });
  }
};

jQuery(function() {
  init_toggle_state();
  init_search();
  init_advsearch();
  init_activemenu();
  return init_export_status();
});

